import React from "react";
import { useTranslation } from "react-i18next";

import DashboardBlock from "../Block";

interface Props {
    isAdmin: boolean;
}

enum AvailableIcons {
    recording = "recording-list",
    userManagement = "user-management",
    accountManagement = "account-management",
    profile = "profile",
}

export interface Block {
    icon: AvailableIcons;
    label: string;
    link: string;
}
const DashboardBlocks = ({ isAdmin }: Props): JSX.Element => {
    const { t } = useTranslation();
    const blocks: Block[] = isAdmin
        ? [
              {
                  icon: AvailableIcons.recording,
                  label: t("Recording List"),
                  link: "recording-list",
              },
              {
                  icon: AvailableIcons.userManagement,
                  label: t("User Management"),
                  link: "user-management",
              },
              {
                  icon: AvailableIcons.accountManagement,
                  label: t("Account Management"),
                  link: "account-management",
              },
              {
                  icon: AvailableIcons.profile,
                  label: t("Profile"),
                  link: "profile",
              },
          ]
        : [
              {
                  icon: AvailableIcons.recording,
                  label: t("Recording List"),
                  link: "recording-list",
              },
              {
                  icon: AvailableIcons.profile,
                  label: t("Profile"),
                  link: "profile",
              },
          ];

    return (
        <>
            {blocks.map((block, index) => (
                <DashboardBlock block={block} key={index} />
            ))}
        </>
    );
};

export default DashboardBlocks;
