import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";

import { useUserService } from "../../../services/user";
import downloadApp from "../../../utils/dowload-app";

const ActionBar = (): JSX.Element => {
    const { t } = useTranslation();
    const { user } = useUserService();

    return (
        <Grid item xs={10}>
            <Grid container spacing={2}>
                {process.env.GATSBY_TOGGLE_STRIPE === "true" && (
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => navigate("/credit-selection/")}
                        >
                            {t("Buy Credits")}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={downloadApp}
                    >
                        {t("Download application")}
                    </Button>
                </Grid>
                {user !== null && (
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={true}
                        >
                            {user?.slots - user?.slotsUsed} {t("credits")}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default ActionBar;
