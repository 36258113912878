import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DomainIcon from "@material-ui/icons/Domain";
import DvrIcon from "@material-ui/icons/Dvr";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import makeStyles from "@material-ui/styles/makeStyles";
import { navigate } from "gatsby";
import React from "react";

import * as styles from "../../../styles/export.module.scss";
import { Block } from "../Blocks";
import * as style from "./style.module.scss";

interface Props {
    block: Block;
}

const useClasses = makeStyles(() => ({
    svg: {
        "&:hover": {
            color: styles.white,
        },
        color: styles.nightblue,
    },
}));

const getIcon = (logo: string) => {
    // eslint-disable-next-line smells/no-switch
    switch (logo) {
        case "recording-list":
            return <DvrIcon fontSize="large" />;
        case "user-management":
            return <PersonAddIcon fontSize="large" />;
        case "account-management":
            return <DomainIcon fontSize="large" />;
        case "profile":
            return <PersonIcon fontSize="large" />;
    }
};

const DashboardBlock: React.FC<Props> = props => {
    const classes = useClasses();
    return (
        <Grid item className={style.wrap}>
            <Grid
                container
                direction="column"
                className={`${style.block} ${classes.svg}`}
                onClick={() => navigate(`/${props.block.link}/`)}
            >
                <Grid item>
                    <Grid
                        container
                        justifyContent="center"
                        alignContent="center"
                    >
                        {getIcon(props.block.icon)}
                    </Grid>
                </Grid>
                <Grid item>
                    <Box width={1} mb={5}>
                        <Typography align="center" variant="body2">
                            {props.block.label}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardBlock;
