import Grid from "@material-ui/core/Grid";
import React from "react";

import AppLayout from "../../components/AppLayout";
import ActionBar from "../../components/Dashboard/ActionBar";
import DashboardBlocks from "../../components/Dashboard/Blocks";
import { useUserService } from "../../services/user";
import * as style from "./style.module.scss";

const Dashboard = (): JSX.Element => {
    const { user } = useUserService();

    return (
        <AppLayout currentPage="Dashboard">
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Grid container spacing={2} justifyContent="center">
                        <ActionBar />
                        <Grid item xs={10}>
                            <Grid
                                className={style.blockGrid}
                                container
                                spacing={2}
                                justifyContent="space-between"
                            >
                                <DashboardBlocks
                                    isAdmin={user?.accountAdmin || false}
                                ></DashboardBlocks>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppLayout>
    );
};

export default Dashboard;
